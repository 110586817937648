export function telefone(n) {
  if (n !== undefined) {
    if (n.length === 11) {
      const cleaned = ('' + n).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    } else {
      return n
    }
  } else {
    return n
  }
}

export function cpf(cpf) {
  if (cpf !== undefined) {
    if (cpf.length === 11) {
      const cleaned = ('' + cpf).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
      if (match) {
        return match[1] + '.' + match[2] + '.' + match[3] + '-' + match[4]
      }
      return null
    } else {
      return cpf
    }
  } else {
    return cpf
  }
}

export function dinheiro(valor) {
  return parseFloat(valor).toFixed(2)
}

export function dataBr(date) {
  return new Date(date).toLocaleString('pt-BR')
}

export function comparaUsoCredito(a, b) {
  if (a.credito > b.credito) {
    return -1
  }
  if (a.credito < b.credito) {
    return 1
  }
  return 0
}

export function translateVia(via) {
  if (via === 'Stock') {
    return 'Caixa'
  } else if (via === 'Manual') {
    return 'ADM'
  } else if (via === 'Transfer') {
    return 'Trasnf'
  } else {
    return via
  }
}
