import React, { useState, useEffect } from 'react'

import { roles, translations } from '../helpers/roles'

const AdminForm = ({ adminData, onSave, adminId, setShowDeleteModal }) => {
  const [admin, setAdmin] = useState({
    name: '',
    document: '',
    email: '',
    pass: '',
    birth: '',
    photo: '',
    phone: '',
    roles: []
  })

  useEffect(() => {
    if (adminData) {
      setAdmin({ ...adminData, birth: adminData.birth ? new Date(adminData.birth).toISOString().split('T')[0] : '' })
    }
  }, [adminData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setAdmin((prevAdmin) => ({
      ...prevAdmin,
      [name]: value
    }))
  }

  const handleRoleChange = (role) => {
    setAdmin((prevAdmin) => {
      const roles = prevAdmin.roles || [] // Garantir que prevAdmin.roles seja um array
      const newRoles = roles.includes(role) ? roles.filter((r) => r !== role) : [...roles, role]
      return {
        ...prevAdmin,
        roles: newRoles
      }
    })
  }

  const saveUser = (e) => {
    e.preventDefault()
    if (adminId) {
      onSave(admin, adminId)
    } else {
      onSave(admin)
    }
  }

  return (
    <div>
      <div>
        <label>Nome Completo:</label>
        <input
          type="text"
          className="my-2 form form-control"
          name="name"
          id="name"
          value={admin.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>CPF:</label>
        <input
          type="number"
          size={11}
          className="my-2 form form-control"
          name="document"
          id="document"
          value={admin.document}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Telefone:</label>
        <input
          type="number"
          className="my-2 form form-control"
          name="phone"
          id="phone"
          value={admin.phone}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          className="my-2 form form-control"
          name="email"
          id="email"
          value={admin.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Senha:</label>
        <input
          type="password"
          className="my-2 form form-control"
          name="pass"
          id="pass"
          value={admin.pass}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Data de Nascimento:</label>
        <input
          type="date"
          className="my-2 form form-control"
          name="birth"
          id="birth"
          value={admin.birth}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Permissões:</label>
        {roles.map((role) => (
          <div key={role}>
            <input
              type="checkbox"
              value={role}
              checked={admin.roles.includes(translations[role])}
              onChange={() => handleRoleChange(role)}
              id={'role' + translations[role]}
            />
            <label>{translations[role]}</label>
          </div>
        ))}
      </div>
      <div className="text-center my-5">
        <button id="btnCreateAdmin" className="btn btn-primary" onClick={saveUser}>
          {adminId ? <>Salvar</> : <>Criar</>}
        </button>
        |
        <button id="btnDeleteAdmin" className="btn btn-danger" onClick={() => setShowDeleteModal(true)}>
          {adminId ? <>Deletar</> : <></>}
        </button>
      </div>
    </div>
  )
}

export default AdminForm
