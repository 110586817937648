import React, { useEffect, useRef, useState } from 'react'
import { getParticipantDataOnEvent } from '../../services/sympla'
import SymplaEntrada from './SymplaEntrada'
import { QrReader } from 'react-qr-reader'
import Modal from 'react-modal'

export default function SymplaQrReader({ event, setEvent }) {
  const [text, setText] = useState('')
  const [symplaUser, setSymplaUser] = useState({})
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [searchQr, setSearchQr] = useState(false)
  const [error, setError] = useState(false)
  const lastResult = useRef()

  const handleResult = (result) => {
    if (lastResult.current === result?.text) {
      return
    }
    if (result?.text && result?.text.length > 3) {
      lastResult.current = result.text
      setText(result?.text)
      closeModal()
      setSearchQr(!searchQr)
    }
  }

  useEffect(() => {
    if (text !== '') {
      getUserInfo()
    }
  }, [searchQr])

  const openModal = () => {
    setModalIsOpen(true)
  }
  // Estilos para o modal
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%' // Largura do modal (ajuste conforme necessário)
    }
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  async function getUserInfo() {
    setError(false)
    if (text.length < 3) {
      setError(true)
      return
    }
    setSymplaUser({})
    getParticipantDataOnEvent(event.id, text)
      .then((d) => {
        if (d.data.first_name) {
          setSymplaUser(d.data)
        } else {
          setError(true)
        }
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <>
      <div className="position-relative">
        <h2>{event.title}</h2>
        <h4>Check-In</h4>
        <button className="btn btn-danger btn-sm position-absolute mt-2 top-0 end-0" onClick={() => setEvent({})}>
          Trocar
        </button>
      </div>

      <div className="m-3 text-center">
        <input
          type="text"
          className="form-control"
          value={text}
          placeholder="Digite o numero do ingresso"
          onChange={(e) => setText(e.target.value)}
        />
        <button className="btn btn-success" onClick={openModal}>
          Scanear QR
        </button>
        <button className="btn btn-primary m-2" onClick={getUserInfo}>
          Buscar Dados
        </button>
      </div>
      {error && <p>Não achei :(</p>}
      {symplaUser.id && (
        <SymplaEntrada
          symplaUser={symplaUser}
          setSymplaUser={setSymplaUser}
          id={event.id}
          participant={text}
          setSearchQr={setSearchQr}
          event={event}
        />
      )}
      <div>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="QR">
          <h2>Scanear QR Code</h2>
          {modalIsOpen && (
            <QrReader constraints={{ facingMode: 'environment' }} onResult={handleResult} style={{ width: '100%' }} />
          )}
          <div className="text-center">
            <button className="btn btn-primary" onClick={closeModal}>
              Sair
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}
