import api from './api'

export async function associateToTagAndAddCredits(data) {
  try {
    const res = await api.post('credit', data)
    if (res.status === 201) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export async function addCreditsAndChangeTag(data) {
  try {
    const res = await api.post('credit/addCreditsAndChangeTag', data)
    if (res.status === 201) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export async function getUserTagById(id) {
  try {
    const res = await api.get(`credit/user/${id}`)
    return res.data
  } catch (e) {
    return null
    console.log(e)
  }
}
export async function registerEntry(data) {
  try {
    const res = await api.post('entrance', data)
    if (res.status === 200) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export async function getCreditPerTag(tag) {
  try {
    const res = await api.get(`credit/user/tag/${tag}`)
    return res.data
  } catch (e) {
    console.log(e)
    return []
  }
}

export async function getCreditPerUserId(id) {
  try {
    const res = await api.get(`credit/user/${id}`)
    return res.data
  } catch (e) {
    console.log(e)
  }
}

export async function getUserCreditHistory(userId, page) {
  try {
    const res = await api.get(`transaction/user/${userId}/page/${page}`)
    return res.data
  } catch (e) {
    console.log(e)
  }
}
export async function getUserComsuptionHistory(userId, page) {
  try {
    const res = await api.get(`consumption/user/${userId}/page/${page}`)
    return res.data
  } catch (e) {
    console.log(e)
  }
}

export async function getCreditTransactionById(id) {
  try {
    const res = await api.get(`transaction/${id}`)
    return res.data
  } catch (e) {
    console.log(e)
  }
}
