import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Cesta.css'

import api from '../services/api'
import LoaderButton from '../components/LoaderButton'
import ModalSucesso from '../components/ModalSucesso'

import { useCart } from 'react-use-cart'

import { FcMinus } from 'react-icons/fc'
import { FiPlus } from 'react-icons/fi'
import { FaTimes, FaShoppingBasket } from 'react-icons/fa'

import { getBar, getClientId } from './../services/auth'
import { hasSaldo, getNeededSaldo, getItemsValueSum } from '../helpers/Bar'

Cesta.propTypes = {
  user: PropTypes.object
}

export default function Cesta({ user, setUser }) {
  const { isEmpty, totalItems, emptyCart, items, cartTotal, updateItemQuantity, removeItem } = useCart()
  const [isL, setIsL] = useState(false)
  const [modal, setModal] = useState(false)
  const [msg, setMsg] = useState('')
  const [comanda, setComanda] = useState(false)
  const [titulo, setTitulo] = useState('')
  const [tipo, setTipo] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalItemsSelled, setTotalItemsSelled] = useState(false)

  function updateItemQuantityRagazzid(item, valor) {
    const cartItem = items.find((v) => v.id === item)
    if (item.promoId) {
      if (valor <= cartItem.maxValue) {
        updateItemQuantity(item, valor)
      }
    } else {
      updateItemQuantity(item, valor)
    }
  }

  function emptyUser() {
    setUser({})
  }

  function canAddItem(p) {
    if (hasSaldo(p, user, totalItems, items)) {
      return true
    } else {
      const saldo = getNeededSaldo(p, totalItems, items) - user.remainingAmount
      alert('Saldo insuficiente!\nFaltam R$ ' + saldo)
      return false
    }
  }

  async function vender() {
    setIsL(true)
    setModal(true)
    setTitulo('Trabalhando')
    setLoading(true)
    setTipo('modal-warning')
    setMsg('Trabalhando na sua operação, aguarde ...')
    const r_items = items.map((i) => ({
      ...i,
      reason: 'Vendido via caixa',
      barName: getBar() ? getBar() : 'Entrada'
      // via: 'Bar'
    }))
    setTotalItemsSelled(getItemsValueSum(items))
    emptyCart()
    try {
      await api.post(`product/sell`, {
        userId: user.userId._id,
        productsArray: r_items
      })
      setMsg('Vendido para ' + user.userId.name)
      setTitulo('Tudo certo')
      setTipo('modal-success')
      setComanda(user.tag)
      setLoading(false)
    } catch (e) {
      console.log(e)
      if (e.response) {
        setMsg(`Pedido nao realizado, motivo: ${JSON.stringify(e.response.message)}`)
      } else {
        setMsg('Pedido nao realizado, consulte um ADM')
      }
      setModal(true)
      setTitulo('Falhamos')
      setTipo('modal-danger')

      setLoading(false)
      setIsL(false)
    }
  }

  if (isEmpty) {
    return (
      <>
        <ModalSucesso
          loading={loading}
          tipo={tipo}
          titulo={titulo}
          msg={msg}
          link={emptyUser}
          show={modal}
          valor={user.remainingAmount - totalItemsSelled}
          comanda={comanda}
        />
        <p>Sem items na cesta</p>
      </>
    )
  }

  return (
    <>
      <ModalSucesso loading={loading} tipo={tipo} titulo={titulo} msg={msg} link={emptyUser} show={modal} />
      <div id="topo" className="fundo container cestaContainer">
        <a href="#ItemsCesta">
          {' '}
          <h4>
            <FaShoppingBasket />
            <small className="color-red">
              {' '}
              <b>({totalItems})</b>{' '}
            </small>
          </h4>
        </a>

        <div className="row cestaItems" id="ItemsCesta">
          {items.map((item) => (
            <span key={item.id} className="text-center col-12">
              <p className="text-center">
                <b>{item.title} </b> ({item.quantity})
              </p>
              <p>
                <img className="small img-fluid" src={item.image} alt={item.title} width="100"></img>
              </p>
              <button
                className="m-2 btn btn-light btn-success"
                onClick={() => updateItemQuantityRagazzid(item.id, item.quantity - 1)}
              >
                <FcMinus />
              </button>
              <button id={`remove-${item.title}`} className="m-2 btn btn-danger" onClick={() => removeItem(item.id)}>
                <FaTimes />
              </button>
              <button
                className="m-2 btn btn-success"
                onClick={() => (canAddItem(item) ? updateItemQuantityRagazzid(item.id, item.quantity + 1) : null)}
              >
                <FiPlus />
              </button>
              <p>R$ {(item.price * item.quantity).toFixed(2)}</p>
            </span>
          ))}
        </div>
        <div className="d-grid gap-2">
          <LoaderButton
            isLoading={isL}
            className="btn btn-warning w-100 btn-hight-40"
            onClick={() => {
              if (window.confirm('Tem certeza?\nTotal de: R$' + cartTotal + ',00')) {
                vender()
              }
            }}
          >
            Vender R$ {cartTotal},00
          </LoaderButton>
        </div>
      </div>
    </>
  )
}
