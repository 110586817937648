import api from './api'

export async function deleteCaixaById(id) {
  try {
    const response = await api.delete('cash-register/' + id)
    if (response.status === 200) {
      return response.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export async function isCaixaOpen() {
  try {
    const res = await api.get('cash-register/operator')
    return res.data
  } catch (e) {
    console.log(e)
    return false
  }
}
export async function getFreeCashRegister() {
  try {
    const res = await api.get('cash-register/free')
    if (res.status === 200) {
      return res.data
    } else {
      return []
    }
  } catch (e) {
    console.log(e)
    return []
  }
}

export async function getCashRegister() {
  try {
    const res = await api.get('cash-register')
    return res.data
  } catch (e) {
    console.log(e)
    return {}
  }
}

export async function transactionAndCredit(data) {
  const res = await api.post('cash-register/transactionAndCredit', data)
  if (res.status === 201) {
    return res.data
  }
}

export async function getCashRegisterById(id) {
  const res = await api.get('cash-register/' + id)
  return res
}

export async function getLastRegisterTransactionsById(id, date) {
  if (date) {
    const res = await api.get(`cash-register/transactions/${id}/${date}`)
    return res.data
  } else {
    const res = await api.get(`cash-register/transactions/${id}`)
    return res.data
  }
}

export async function getTransactionsByCashRegisterId(id) {
  const res = await api.get(`cash-register/transaction/${id}`)
  return res.data
}
