import api from '../services/api'
import { getClientId } from '../services/auth'
import { telefone } from './Formata'
import { translateList, translations } from './roles'

export function getUserCredits(transactions) {
  if (transactions && transactions.length > 0) {
    return transactions.filter((t) => !t.isReversed && t.remainingAmount > 0).reduce((a, t) => a + t.remainingAmount, 0)
  }

  return 0
}

export function getUserComanda(comanda) {
  if (comanda) {
    const userCredit = comanda.find((c) => c.client.id === getClientId())
    if (userCredit) {
      return userCredit.number
    } else {
      return 0
    }
  } else {
    return comanda
  }
}

export function getUserRole(user) {
  const client = user.clients.find((c) => String(c.id) === getClientId())
  const translated = translateList(client.roles)
  return translated.join(', ')
}

export function splitName(fullName) {
  const parts = fullName.split(' ')
  if (parts.length === 1) {
    return [fullName, '']
  }
  const firstName = parts[0]
  const rest = parts.slice(1).join(' ')
  return [firstName, rest]
}

export function getByClient(usage) {
  if (usage) {
    return usage.filter((c) => c.client.id === getClientId())
  } else {
    return []
  }
}

export function parseName(user) {
  if (user.name) {
    return user.name + ' ' + user.lastname ?? ''
  } else {
    return 'Não Informado'
  }
}

export function parseTelOrCpf(user) {
  if (user.phone) {
    return telefone(user.phone)
  } else {
    return user.doc
  }
}

export async function associateUserWithComandaAndAddCredits(
  credit,
  entry,
  userId,
  tag,
  payment,
  postData,
  phoneNumber
) {
  try {
    if (parseInt(credit) > 100) {
      // setIsLoading(false)
      alert('O crédito não pode ser maior que R$ 100,00')
      alert('Operação não realizada, tente novamente')
      return 0
    }

    const res = await api.post(`users/${userId}/comanda`, {
      comanda: tag,
      creditos: parseInt(credit),
      pagamento: payment,
      postData
    })
    if (res.status !== 200) {
      alert('Falha ao associar usuário com comanda')
      return false
    }
    if (parseInt(entry, 10) > 0) {
      try {
        await api.post('entrada/pagamento', {
          comanda: tag,
          entrada: parseInt(entry, 10),
          tel: phoneNumber,
          pagamento: payment
        })
        console.log('Entrada Registrada')
        return true
      } catch (e) {
        alert(`Falha\n${e}`)
        return false
      }
    }
    return true
  } catch (e) {
    alert(`Falha\n${e}`)
    return false
  }
}
