import React, { useEffect, useState } from 'react'
import AutoComplete from '../AutoComplete'
import { findUserByText } from '../../services/users'
import { checkin } from '../../services/sympla'
import getUserByComanda from '../../services/comanda'
import { getUserComanda } from '../../helpers/Users'
import { isCaixaOpen } from '../../services/caixa'
import LoaderButton from '../LoaderButton'
import Form from 'react-bootstrap/Form'
import PaymentType from '../Caixa/PaymentType'
import InputMask from 'react-input-mask'

const SymplaEntrada = ({ symplaUser, id, participant, setSymplaUser, setSearchQr, event }) => {
  const [comanda, setComanda] = useState(0)
  const [isGringo, setIsGringo] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [consuma, setConsuma] = useState(0)
  const [comandaInUse, setComandaInUse] = useState(0)
  function handleGringo() {
    setIsGringo(!isGringo)
  }

  const [caixa, setCaixa] = useState(false)
  const [doc, setDoc] = useState('')
  const [gringo, setGringo] = useState(false)
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  // const [tag, setTag] = useState(0)
  const [email, setEmail] = useState('')
  const [bd, setBd] = useState('')
  // const [entry, setEntry] = useState(0)
  // const [credit, setCredit] = useState(0)
  const [payment, setPayment] = useState('')
  // const [queryString, setQueryString] = useState('')
  const [userFound, setUserFound] = useState({})

  useEffect(() => {
    setName(symplaUser.first_name + ' ' + symplaUser.last_name)
    setEmail(symplaUser.email)
    if (symplaUser.custom_form.length > 0) {
      const sTel = symplaUser.custom_form.find((c) => c.name.toLowerCase() === 'telefone')
      const sDoc = symplaUser.custom_form.find((c) => c.name.toLowerCase() === 'cpf')
      if (sTel && sTel.value) {
        setTel(sTel.value.match(/\d+/g).join(''))
      }
      if (sDoc && sDoc.value) {
        setDoc(sDoc.value.match(/\d+/g).join(''))
      }
    }
    // findUserByText(symplaUser.email).then((d) => {
    //   if (d.cpf) {
    //   }
    // })
  }, [symplaUser])

  useEffect(() => {
    if (userFound.doc) {
      setDoc(userFound.doc)
      setTel(userFound.phone)
      setBd(new Date(userFound.birth).toLocaleDateString('pt-BR'))
    }
  }, [userFound])
  // useEffect(() => {
  //   isCaixaOpen().then((e) => {
  //     if (e) {
  //       setCaixa(e)
  //     }
  //   })
  // }, [])

  async function associateAndCheckin() {
    setIsLoading(true)
    // Garante que ta tudo certo com a comanda
    // Pergunta pro jovem
    setComandaInUse(0)

    const user = await getUserByComanda(comanda)
    if (user.length > 0) {
      if (user[0].tel === tel) {
        setComandaInUse(getUserComanda(user[0].comanda))
      } else {
        setComandaInUse(1)
      }
      setIsLoading(false)
      return
    }

    const ctz = window.confirm('Tem certeza')
    if (ctz) {
      const formData = {
        comanda: comanda,
        tel: tel,
        email,
        nome: name,
        consuma: consuma,
        doc,
        nascimento: bd,
        pagamento: payment
      }
      try {
        const checkIn = await checkin(id, participant, formData)
        setSymplaUser({})
        setIsLoading(false)
        setSearchQr('')
        if (checkIn.data.id) {
          alert('Tudo certo!')
        } else {
          alert('Problemas')
        }
      } catch (e) {
        alert('Problemas :(')
        setIsLoading(false)
        alert(JSON.stringify(e.message))
        setSymplaUser({})
        setIsLoading(false)
        setSearchQr('')
        console.log(e)
      }
    }
    setIsLoading(false)
  }

  async function checkIn() {
    setIsLoading(true)

    const ctz = window.confirm('Tem certeza que deseja fazer o checking sem associar uma comanda?')
    if (ctz) {
      const formData = {
        tel: tel,
        email,
        nome: name,
        doc,
        nascimento: bd,
        event
      }
      try {
        await checkin(id, participant, formData)
        alert('Tudo certo!')
        setSymplaUser({})
        setIsLoading(false)
        setSearchQr('')
      } catch (e) {
        alert('Problemas :(')
        setIsLoading(false)
        alert(JSON.stringify(e.message))
        console.log(e)
      }
    }
    setIsLoading(false)
  }

  function getAvailableCheckIn(array) {
    let i = 0
    for (const c of array) {
      if (!c.check_in) {
        i = i + 1
      }
    }
    return i
  }
  function getUsedCheckin(array) {
    let i = 0
    for (const c of array) {
      if (c.check_in) {
        i = i + 1
      }
    }
    return i
  }

  // function validateForm() {
  //   return true;
  //   // console.log(parseInt(comanda, 10) > 0 && tel.length > 1);
  //   // return parseInt(comanda, 10) > 0 && tel.length > 1;
  // }

  return (
    <div>
      <h2>Check-In</h2>
      <h3>{symplaUser.ticket_name}</h3>
      <h5>Valor: R$ {symplaUser.ticket_sale_price} </h5>
      {symplaUser.order_discount && symplaUser?.order_discount.match(/\d+\.\d+/) > 0 && (
        <h5>Desconto: {symplaUser.order_discount}</h5>
      )}
      <div className="p-3">
        <p className="mt-3">Nome</p>
        <input
          type="text"
          className="form form-control mt-3"
          id="comanda"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <p className="mt-3">Email</p>
        <input
          type="text"
          className="form form-control mt-3"
          id="comanda"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <p className="mt-3">Documento</p>
        <AutoComplete id="doc" query={doc} setQuery={setDoc} setUserFound={setUserFound} filled={false} />
        Gringo <input className="form m-4" type="checkbox" onClick={handleGringo} />
        <p>Telefone</p>
        <input id="telefone" className="form form-control" value={tel} onChange={(e) => setTel(e.target.value)} />
        <>
          <Form.Label className="mt-4"> Data de Nascimento </Form.Label>
          <InputMask
            mask="99/99/9999"
            value={bd}
            onChange={(e) => setBd(e.target.value)}
            className={'is-valid form-control'}
          />
        </>
        {caixa ? (
          <>
            <p className="mt-3">Comanda</p>
            <input
              type="number"
              className={parseInt(comanda, 10) > 0 ? 'is-valid form-control mt-3' : 'is-invalid form-control mt-3'}
              id="comanda"
              onChange={(e) => setComanda(e.target.value)}
              value={comanda}
            />
            <p className="mt-3">Consumação</p>
            <input
              type="number"
              className={parseInt(consuma, 10) > 0 ? 'is-valid form-control mt-3' : 'is-invalid form-control mt-3'}
              id="comanda"
              onChange={(e) => setConsuma(e.target.value)}
              value={consuma}
            />
            <PaymentType setPayment={setPayment} payment={payment} />
          </>
        ) : (
          <div className="my-4 alert alert-warning">
            <p>
              Caixa não está aberto, impossível associar a comanda ou adicionar créditos, usuário será adicionado ao
              evento
            </p>
          </div>
        )}
        {comandaInUse ? (
          <>
            {comandaInUse === 1 && (
              <>
                <div className="card bg-warning m-3">
                  <div className="card-body">Comanda já associada a outra pessoa</div>
                </div>
              </>
            )}

            {comandaInUse > 1 && (
              <>
                <div className="card bg-warning m-3">
                  <div className="card-body">
                    Esse usuário já está associado a comanda {comandaInUse}, sera que esse checkin nao é pra outra
                    pessoa? :)
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="mt-5 text-center">
          <h4>Ingessos disponíveis</h4>
          {getAvailableCheckIn(symplaUser.checkin) ? (
            <>
              <p>Disponíveis {getAvailableCheckIn(symplaUser.checkin) - getUsedCheckin(symplaUser.checkin)}</p>

              {!caixa ? (
                <LoaderButton
                  className="btn btn-primary"
                  onClick={checkIn}
                  disabled={tel.length < 3}
                  isLoading={isLoading}
                >
                  Fazer Checking
                </LoaderButton>
              ) : (
                <LoaderButton
                  className="btn btn-primary"
                  onClick={associateAndCheckin}
                  disabled={tel.length < 3 || (payment !== '' && consuma > 0)}
                  isLoading={isLoading}
                >
                  Associar a comanda e fazer Checkin
                </LoaderButton>
              )}
            </>
          ) : (
            <p>Check-In Concluído</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SymplaEntrada
