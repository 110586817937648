import React, { useEffect, useState } from 'react'
import api from '../services/api'
import { telefone } from '../helpers/Formata'
import Loading from '../components/Loading'
import { Link } from 'react-router-dom'
export default function Bloqueados() {
  const [usersBloqueados, setUsersBloqueados] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    onLoad()
  }, [page, limit])

  async function onLoad() {
    setIsLoading(true)
    const res = await api.get(`/users/blocked/${page}/${limit}`)
    setUsersBloqueados(res.data)
    setIsLoading(false)
    setLoaded(true)
  }

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))
  }

  return (
    <div>
      <h1>Lista de Usuários Bloqueados</h1>
      {loaded ? (
        <div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Telefone</th>
                <th scope="col">Bloqueado em</th>
                <th scope="col">Motivo</th>
                <th scope="col">Até</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5">
                    <Loading size={60} />
                  </td>
                </tr>
              ) : (
                <>
                  {usersBloqueados.length ? <></> : <>Nao tenho nada pra mostrar :( - Bloqueie alguém, rs</>}

                  {usersBloqueados.map((u) => (
                    <tr key={u.id}>
                      <th scope="row">
                        <Link to={`/usuario/${u.userId._id}`}>{u.userId.name}</Link>
                      </th>
                      <td>{telefone(u.userId.phone)}</td>
                      <td>{new Date(u.createdAt).toLocaleDateString('BR')}</td>
                      <td>{u.reason}</td>
                      <td>{new Date(u.blockedUntil).toLocaleDateString('pt-BR')}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button className="btn btn-primary mx-2" onClick={handlePrevPage} disabled={page === 1}>
              Anterior
            </button>
            <span className="mx-2">Página {page}</span>
            <button
              className="btn btn-primary mx-2"
              onClick={handleNextPage}
              disabled={usersBloqueados.length <= limit}
            >
              Próxima
            </button>
          </div>
        </div>
      ) : (
        <>Carregando...</>
      )}
    </div>
  )
}
