import React, { useEffect, useState } from 'react'

import api from '../services/api'

import CaixaAbertos from '../components/CaixaAbertos'
import LoaderButton from '../components/LoaderButton'
import Modal from '../components/Modal'
import { deleteCaixaById } from '../services/caixa'

export default function CaixaAdmin() {
  useEffect(() => {
    getSangria()
    getCaixas()

    // eslint-disable-next-line
  }, [])

  const [caixas, setCaixas] = useState([])
  const [numeroCaixa, setNumeroCaixa] = useState('')
  const [trocoInicial, setTrocoInicial] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({})
  const [totalSangria, setTotalSangria] = useState(0)

  async function getCaixas() {
    const res = await api.get('/cash-register')
    setCaixas(res.data.sort((a, b) => a.name - b.name))
  }

  async function deleteCaixaByIdAndUpdateState(id) {
    await deleteCaixaById(id)
    await getCaixas()
  }

  async function getSangria() {
    const sangria = await api.get('/cash-register/cash/sum/last/depletion')
    if (sangria.data) {
      setTotalSangria(sangria.data * -1)
    }
  }

  async function handleSubmit() {
    setIsLoading(true)
    try {
      setModal({
        show: true,
        type: 'warning',
        title: 'Tentando criar o caixa',
        msg: 'Aguarde...'
      })

      await api.post('/cash-register', { valorInicial: parseInt(trocoInicial), name: numeroCaixa })
      setIsLoading(false)

      setModal({
        show: true,
        type: 'success',
        title: 'Caixa Criado',
        msg: 'Deu tudo certo!',
        link: '/caixaAdm',
        refresh: true
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response?.data?.error || e.message,
        link: '/caixaAdm',
        refresh: true
      })
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  function validateForm() {
    return numeroCaixa > 0 && trocoInicial >= 0
  }

  async function clearTags() {
    const prompt = window.confirm('Você tem certeza que quer as comandas? Se tiver evento rodando vai quebrar tudo')
    if (prompt) {
      const p = window.prompt('Digite a senha')
      if (p === 'ragazzid') {
        try {
          await api.delete('credit/tags/clear')
          setModal({
            show: true,
            type: 'success',
            title: 'Sucesso',
            msg: 'Deu tudo certo!',
            link: '/'
          })
        } catch (e) {
          setModal({
            show: true,
            type: 'danger',
            title: 'Erro',
            msg: JSON.stringify(e.message),
            link: '/'
          })
        }
      }
    }
  }
  async function limparFilaCaixa() {
    const prompt = window.confirm('Você tem certeza que quer limpar a fila do caixa?')
    if (prompt) {
      setModal({
        show: true,
        type: 'warning',
        title: 'Tentando limpar a fila do caixa',
        msg: 'Aguarde...'
      })
      try {
        await api.delete('host/queue')
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/'
        })
      } catch (e) {
        setModal({
          show: true,
          type: 'danger',
          title: 'Erro',
          msg: JSON.stringify(e),
          link: '/'
        })
      }
    }
  }

  return (
    <div>
      <Modal modal={modal} />
      <h1>Adm Caixa</h1>
      <h3>Novo Caixa</h3>
      <div className="form-group">
        <label htmlFor="iptCaixa">Número do caixa</label>
        <input
          type="number"
          onChange={(e) => setNumeroCaixa(e.target.value)}
          className="form-control"
          id="iptCaixa"
          aria-describedby="iptCaixa"
        />
        <small id="spnCaixa" className="form-text text-muted">
          O número deve ser maior que 0 e não pode ser repetido
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="iptTrocoInicial">Troco Inicial</label>
        <input
          type="number"
          className="form-control"
          onChange={(e) => setTrocoInicial(e.target.value)}
          id="iptTrocoInicial"
          aria-describedby="iptTrocoInicial"
        />
        <small id="spnTrocoInicial" className="form-text text-muted">
          Quanto de dinheiro tem nesse caixa agora?
        </small>
      </div>
      <LoaderButton
        disabled={!validateForm()}
        onClick={handleSubmit}
        isLoading={isLoading}
        type="submit"
        className="btn btn-primary"
      >
        Criar
      </LoaderButton>
      <hr />
      <h3>Total Sangria (mais ou menos 12 horas de retiradas)</h3>
      <h4 style={{ color: 'red' }}>R$ {totalSangria},00</h4>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <h3>Remover pessoas da fila do caixa</h3>
            <button id="clearQueue" onClick={limparFilaCaixa} className="btn btn-danger">
              Limpar fila do Caixa
            </button>
          </div>
          <div className="col-sm">
            <h3>Limpar as comandas</h3>
            <button id="clearQueue" onClick={clearTags} className="btn btn-danger">
              Limpar comandas
            </button>
          </div>
        </div>
      </div>

      <hr />

      <h2>Caixas</h2>

      {caixas.length > 0 ? (
        <CaixaAbertos caixas={caixas} detalhes={false} deleteCaixa={deleteCaixaByIdAndUpdateState} />
      ) : (
        <>Procurando...</>
      )}

      <hr />
      <a href="caixasGeral" alt="Todos os Caixas">
        Todos os Caixas
      </a>
    </div>
  )
}
