export function sumVailableCredits(creditTransactions) {
  return creditTransactions
    .filter((c) => new Date(c.expiresAt) > new Date())
    .reduce((acc, t) => acc + t.remainingAmount, 0)
}

export function translatePaymentType(type) {
  switch (type) {
    case 'CARD':
      return 'Cartão de Crédito'
    case 'CASH':
      return 'Dinheiro'
    case 'bank_transfer':
      return 'Bank Transfer'
    default:
      return 'Unknown'
  }
}

export function translateVia(via) {
  switch (via) {
    case 'Stock':
      return 'Caixa'
    case 'Undo':
      return 'Desfeito'
    default:
      return via
  }
}
