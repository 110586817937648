import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import Modal from '../components/Modal'
import Loading from '../components/Loading'
import { getCreditTransactionById } from '../services/credit'
import { getTransactionsByCashRegisterId } from '../services/caixa'
import { translatePaymentType } from '../helpers/Credits'

export default function CaixaDesfazer() {
  const params = useParams()

  const [data, setData] = useState({})
  const [modal, setModal] = useState({})
  const [amount, setAmount] = useState(0)
  const [transaction, setTransaction] = useState({})
  const [cashTransaction, setCashTransaction] = useState({})

  useEffect(() => {
    onLoad()
  }, [])

  async function returnCreditAndMoneyToCash() {
    const formData = {
      cashTransactionId: transaction.cashRegisterTransactionId,
      transactionId: transaction._id,
      amount
    }

    try {
      await api.post('cash-register/undoTransactionAndCredit', formData)
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Desfeito com sucesso!',
        link: '/caixaOperar'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response.data.message,
        link: '/'
      })
    }
  }
  async function voltaCredito() {
    setModal({
      show: true,
      type: 'warning',
      title: 'Aguarde...',
      loading: true,
      msg: 'Tentando desfazer a venda'
    })

    if (transaction.cashRegisterTransactionId) {
      await returnCreditAndMoneyToCash()
    } else {
      await removeCredits()
    }
  }

  async function removeCredits() {
    const formObjet = {
      creditId: transaction.creditId,
      transactionId: transaction._id,
      amount
    }
    try {
      await api.post('credit/undo', formObjet)
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Desfeito com sucesso!',
        link: '/caixaOperar'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response.data.message,
        link: '/'
      })
    }
  }

  async function onLoad() {
    try {
      const r = await getCreditTransactionById(params.creditTransactionId)
      if (r.userId) {
        setTransaction(r)
        setAmount(r.remainingAmount)
        if (transaction.isReversed) {
          alert('Já foi desfeito :(')
          window.location = '/caixa'
        }
      }
      if (r.cashRegisterTransactionId) {
        const cash = await getTransactionsByCashRegisterId(r.cashRegisterTransactionId)
        setCashTransaction(cash)
      }
    } catch (e) {
      console.log(e)

      alert(e.response.data.message)
    }
  }

  function handleAmountChange(value) {
    const numericValue = parseFloat(value)
    if (numericValue < 0) {
      alert('O valor não pode ser negativo.')
      setAmount(0)
    } else if (numericValue > transaction.remainingAmount) {
      alert('O valor não pode ser maior que o valor da transação.')
      setAmount(transaction.remainingAmount)
    } else {
      setAmount(numericValue)
    }
  }

  function vorta() {
    window.history.back()
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Tem certeza que deseja desfazer o crédito?</h1>
      <div className="text-center">
        {Number.isInteger(transaction.amount) ? (
          <>
            <h2>Informações</h2>
            Crédito adicionado por:
            <p>
              <br></br> <b>{transaction.adminName}</b> @ {new Date(transaction.createdAt).toLocaleString('pt-BR')}
            </p>
            <p>
              <div className="row">
                <div className="col">
                  <h4>Valor Adicionado</h4>
                  <p>{transaction.amount.toFixed(2)}</p>
                </div>
                <div className="col">
                  <h4>Restante</h4>
                  <p>{transaction.remainingAmount.toFixed(2)}</p>
                </div>
              </div>
            </p>
            <h3> Comanda</h3>
            <h2> {transaction.tag}</h2>
            <div>
              <div>
                <label htmlFor="amountInput">Insira o valor a ser desfeito:</label>
                <input
                  id="amountInput"
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={(e) => handleAmountChange(e.target.value)}
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                />
              </div>
              {cashTransaction.type ? <h2>{translatePaymentType(cashTransaction.type)}</h2> : <></>}
              <button className="btn btn-success my-3 mx-3" onClick={vorta}>
                {' '}
                Voltar
              </button>
              <button id="desfazerVenda" className="btn btn-danger my-3" onClick={() => voltaCredito(transaction._id)}>
                {' '}
                Sim
              </button>
            </div>
          </>
        ) : (
          <Loading size={60} />
        )}
      </div>
    </div>
  )
}
