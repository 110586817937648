import api from './api'

export async function getCurrentEvent() {
  const response = await api.get(`integrations/sympla/events`)
  if (response.status === 200) {
    return response.data.data
  } else {
    return []
  }
}

export async function getParticipantDataOnEvent(eventId, participant) {
  const response = await api.get(`integrations/sympla/event/${eventId}/${participant}`)
  if (response.status === 200) {
    return response.data
  } else {
    return []
  }
}

export async function checkin(eventId, participant, formData) {
  const response = await api.post(`integrations/sympla/event/${eventId}/${participant}`, formData)
  if (response.status === 200) {
    return response.data
  } else {
    return []
  }
}
