import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import { getEntry, getExit, totalCard, totalCash } from '../helpers/Numbers'
import CaixaTransactions from '../containers/Admin/CaixaTransactions'
CaixaAbertos.propTypes = {
  caixas: PropTypes.array,
  deleteCaixa: PropTypes.func
}

export default function CaixaAbertos({ caixas, deleteCaixa }) {
  const [show, setShow] = useState(false)
  const [selectedCaixa, setSelectedCaixa] = useState({})

  const handleClose = () => {
    setShow(false)
    setSelectedCaixa({})
  }
  const handleShow = (caixa) => {
    setShow(true)
    setSelectedCaixa(caixa)
  }
  return (
    <div>
      <Modal id="modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar Caixa</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que quer remover o caixa {selectedCaixa.number} ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            variant="danger"
            id="delete-caixa-modal"
            onClick={() => {
              deleteCaixa(selectedCaixa._id)
              handleClose()
            }}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
      {caixas.map((c) => (
        <div key={c._id} id={c.name} className="card my-2">
          <div className="card-header text-center">
            <h3>
              {c.name} {c.adminName}
            </h3>
          </div>
          <div className="card-body my-2">
            <div className="container">
              <div className="row my-2">
                <div className="col">Troco Inicial</div>

                <div className="col" id="trocoInicial">
                  R$ {c.initialCashAmount}
                  ,00
                </div>
              </div>
              <CaixaTransactions caixaId={c._id} initialCashAmount={c.initialCashAmount} closedAt={c.closedAt} />
              <div className="row my-2">
                <div className="col">
                  <a id="btnAdicionar" href={`caixaAdicionar/${c._id}`} className="btn btn-primary">
                    Adicionar
                  </a>
                </div>
                <div className="col">
                  <a id="btnRemover" href={`caixaRemover/${c._id}`} className="btn btn-warning">
                    Retirar
                  </a>
                </div>
              </div>
              <div className="row my-2">
                <div className="col">
                  <a id="btnDetalhes" href={`caixaDetalhes/${c._id}`} className="btn btn-success">
                    Detalhes
                  </a>
                </div>
                <div className="col">
                  <a id="btnFechar" href={`caixaFechar/${c._id}/caixaAdm`} className="btn btn-danger my-2">
                    Fechar
                  </a>
                </div>
              </div>
              <div>
                <a href={`caixa/admin/${c._id}`} className="btn btn-warning">
                  Alterar valor em caixa (caixa inicial)
                </a>
              </div>
              <div className="my-4">
                <button id={`delete-caixa-${c.name}`} onClick={() => handleShow(c)} className="btn btn-danger">
                  Deletar caixa
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
