import React, { useState, useEffect } from 'react'
import { getUserBlocksById } from '../../services/users'
import UserCreditUsage from './UserCreditUsage'
import { formatCreationDate } from '../../helpers/Datas'
import BlockUserModal from './BlockUserModal'
const UserForm = ({ userData, onSave, userId, deleteUser }) => {
  const [user, setUser] = useState({
    name: '',
    doc: '',
    email: '',
    birth: '',
    photo: '',
    phone: '',
    isBlocked: false
  })
  const [blockUserModalIsOpen, setBlockUserModalIsOpen] = useState(false)
  const [userBlocked, setUserBlocked] = useState([])

  const handleBlockUserModalOpen = () => {
    setBlockUserModalIsOpen(true)
  }

  const handleBlockUserModalClose = () => {
    setBlockUserModalIsOpen(false)
  }

  useEffect(() => {
    if (userData) {
      setUser({ ...userData, birth: userData.birth ? new Date(userData.birth).toISOString().split('T')[0] : '' })
    }
  }, [userData])

  useEffect(() => {
    if (userId) {
      getUserBlocksById(userId).then((data) => {
        if (data.length > 0) {
          setUserBlocked(data)
        }
      })
    }
  }, [userData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser((prevuser) => ({
      ...prevuser,
      [name]: value
    }))
  }
  const action = () => {
    if (userId) {
      onSave(user, userId)
    } else {
      onSave(user)
    }
  }

  return (
    <div>
      <BlockUserModal isOpen={blockUserModalIsOpen} onClose={handleBlockUserModalClose} userId={userId} />
      <div>
        {userId && (
          <div>
            <label>Membro desde:</label>
            <div />
            {userData.createdAt ? new Date(userData.createdAt).toLocaleString('pt-Br') : ''} -{' '}
            {userData.createdAt ? formatCreationDate(new Date(userData.createdAt)) : ''}
          </div>
        )}
        <label>Nome Completo:</label>
        <input
          type="text"
          className="my-2 form form-control"
          name="name"
          value={user.name}
          onChange={handleChange}
          id="name"
          required
        />
      </div>
      <div>
        <label>CPF:</label>
        <input
          type="number"
          size={11}
          className="my-2 form form-control"
          name="doc"
          id="doc"
          value={user.doc}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Telefone:</label>
        <input
          type="number"
          className="my-2 form form-control"
          name="phone"
          value={user.phone}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          className="my-2 form form-control"
          name="email"
          id="email"
          value={user.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <label>Data de Nascimento:</label>
        <input
          type="date"
          id="birth"
          className="my-2 form form-control"
          name="birth"
          value={user.birth}
          onChange={handleChange}
        />
      </div>
      <div className="text-center my-5">
        <button className="btn btn-primary mx-5" onClick={action}>
          {userId ? <>Salvar</> : <>Criar</>}
        </button>

        {userId && (
          <>
            <button id="btnApagarUsuario" className="mx-5 btn btn-danger ml-3" onClick={() => deleteUser(userId)}>
              Excluir
            </button>
            {userData.blockedUntil ? (
              <button
                id="btnDesbloquearUsuario"
                className="mx-5 btn btn-primary ml-3"
                onClick={handleBlockUserModalOpen}
              >
                Desbloquear
              </button>
            ) : (
              <button id="btnBloquearUsuario" className="mx-5 btn btn-warning ml-3" onClick={handleBlockUserModalOpen}>
                Status de Booqueio
              </button>
            )}
          </>
        )}
      </div>

      {userId && <UserCreditUsage userId={userId} />}
      {userBlocked.length > 0 && (
        <div className="text-left my-5">
          <h1>Bloqueios</h1>
          {userBlocked.map((block) => (
            <div className={`alert ${block.wasUnblocked ? 'alert-warning' : 'alert-danger'}`} key={block.id}>
              <h4 className="alert-heading">
                Bloqueado em: {new Date(block.createdAt).toLocaleString('pt-BR')} por {block.adminName}
              </h4>
              <p className="mb-0">{block.reason}</p>
              <p className="my-2">Bloqueado até: {new Date(block.blockedUntil).toLocaleString('pt-BR')}</p>
              <p className="my-2">{block.wasUnblocked ? 'Desbloqueado antes da data' : 'Bloqueado até a data acima'}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default UserForm
