import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import api from '../services/api'
import { getCreditPerTag } from '../services/credit'
import Loading from '../components/Loading'
import { Link } from 'react-router-dom'

const Tag = () => {
  const [tag, setTag] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [creditObject, setCreditObject] = useState({})
  const [error, setError] = useState('')

  const handleSearch = async () => {
    setIsLoading(true)
    const res = await getCreditPerTag(tag)
    if (res && res.userId) {
      setError('')
      setCreditObject(res)
    } else {
      setError('Não achei ngm assocaido :(')
    }
    setIsLoading(false)
  }

  return (
    <Container>
      <h1>Procurar por Usuários por Comanda</h1>
      <Row className="my-4">
        <Col>
          <Form>
            <Form.Group controlId="formTag">
              <Form.Label>Digite o número da comanda</Form.Label>
              <Form.Control type="text" placeholder="1" value={tag} onChange={(e) => setTag(e.target.value)} />
            </Form.Group>
            <Button variant="primary my-4" onClick={handleSearch}>
              Search
            </Button>
          </Form>
        </Col>
      </Row>
      {isLoading && (
        <p>
          <Loading size={60} />
          ...
        </p>
      )}
      {error && <p>{error}</p>}
      {creditObject && creditObject.userId && (
        <Card>
          <Card.Header>Informações Básicas do Usário</Card.Header>
          <Card.Body>
            <Card.Title>{creditObject.userId.name}</Card.Title>
            <Card.Text>
              <strong>Email:</strong> {creditObject.userId.email}
            </Card.Text>
            <Card.Text>
              <strong>Document:</strong> {creditObject.userId.doc}
            </Card.Text>
            <Card.Text>
              <strong>Phone:</strong> {creditObject.userId.phone}
            </Card.Text>
            <Card.Text>
              <Link to={`/usuario/${creditObject.userId._id}`}> Editar </Link>
            </Card.Text>
          </Card.Body>
        </Card>
      )}

      {creditObject && creditObject.transactions && creditObject.transactions.length > 0 && (
        <Card className="mt-4">
          <Card.Header>Operações de Crédito</Card.Header>
          <Card.Body>
            {creditObject.transactions.map((transaction, index) => (
              <div key={transaction._id} className="mb-3">
                <Card.Text>
                  <strong>Valor:</strong> {transaction.amount}
                </Card.Text>
                <Card.Text>
                  <strong>Valor Disponível:</strong> {transaction.remainingAmount}
                </Card.Text>
                <Card.Text>
                  <strong>Criado as:</strong> {new Date(transaction.createdAt).toLocaleString()}
                </Card.Text>
                <Card.Text>
                  <strong>Expira em:</strong> {new Date(transaction.expiresAt).toLocaleString()}
                </Card.Text>
                <Card.Text>
                  <strong>Adicionado por:</strong> {transaction.adminName}
                </Card.Text>
                <Card.Text>
                  <strong>Via:</strong> {transaction.via}
                </Card.Text>
                <hr />
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </Container>
  )
}

export default Tag
