import React from 'react'

const UserConsumption = ({
  userTransactions,
  handlePreviousTransactionPage,
  handleNextTransactionPage,
  transactionsPage
}) => {
  return (
    <div>
      <h4>Histórico de Consumos</h4>
      {userTransactions.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Quantidade</th>
              <th>Total</th>
              <th>Operador</th>
            </tr>
          </thead>
          <tbody>
            {userTransactions.map((transaction) => (
              <tr key={transaction._id}>
                <td>
                  {new Date(transaction.createdAt).toLocaleString('pt-Br', {
                    day: '2-digit',
                    month: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </td>
                <td>{transaction.products.map((product) => product.title).join(', ')}</td>
                <td>{transaction.products.map((product) => product.quantity).join(', ')}</td>
                <td>{transaction.totalAmount}</td>
                <td>{transaction.adminName ? transaction.adminName.split(' ')[0] : transaction.adminName}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="6">
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary"
                    onClick={handlePreviousTransactionPage}
                    disabled={transactionsPage === 1}
                  >
                    Anterior
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleNextTransactionPage}
                    disabled={userTransactions.length < 10}
                  >
                    Próximo
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <span>Usuário ainda não tem histórico de consumos</span>
      )}
    </div>
  )
}

export default UserConsumption
