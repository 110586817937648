import React, { useEffect, useState } from 'react'
import { getUserComsuptionHistory, getUserCreditHistory } from '../../services/credit'
import { formatFutureDuration } from '../../helpers/Datas'
import UserConsumption from '../../containers/Consumption/UserConsumption'
import { translateVia } from '../../helpers/Formata'
import Loading from '../Loading'

export default function UserCreditUsage({ userId }) {
  const [userCredit, setUserCredit] = useState([])
  const [userCreditPage, setUserCreditPage] = useState(1)
  const [userTransactions, setUserTransactions] = useState([])
  const [transactionsPage, setTransactionPage] = useState(1)
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false)
  const [isLoadingCredit, setIsLoadingCredit] = useState(false)

  useEffect(() => {
    getUserCreditHistory(userId, userCreditPage).then((e) => {
      setIsLoadingCredit(true)
      setUserCredit(e)
      setIsLoadingCredit(false)
    })
  }, [userId])

  useEffect(() => {
    getUserCreditHistory(userId, userCreditPage).then((e) => {
      setUserCredit(e)
    })
  }, [userId, userCreditPage])

  const handleNextPage = () => {
    setUserCreditPage((prevPage) => prevPage + 1)
  }

  const handlePreviousPage = () => {
    setUserCreditPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))
  }

  useEffect(() => {
    getUserComsuptionHistory(userId, transactionsPage).then((e) => {
      setIsLoadingTransactions(true)
      setUserTransactions(e)
      setIsLoadingTransactions(false)
    })
  }, [userId, transactionsPage])

  const handleNextTransactionPage = () => {
    setTransactionPage((prevPage) => prevPage + 1)
  }

  const handlePreviousTransactionPage = () => {
    setTransactionPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))
  }

  if (!userCredit) {
    return <h4>Usuário ainda não adicionou créditos</h4>
  }
  return (
    <div className="my-5">
      <h3>Créditos & Consumos</h3>

      <div>
        {userCredit.length !== 0 ? (
          <span>Tag atual {userCredit.tag}</span>
        ) : (
          <span>Usuário não está associado a uma comanda no momento</span>
        )}
      </div>
      <div>
        {userCredit.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Data</th>
                <th>Descrição</th>
                <th>Comanda</th>
                <th>Validade</th>
                <th>Restante</th>
                <th>Consumido</th>
                <th>Operador</th>
              </tr>
            </thead>
            <tbody>
              {userCredit.map((t) => (
                <tr key={t._id}>
                  <td>
                    {new Date(t.createdAt).toLocaleString('pt-Br', {
                      day: '2-digit',
                      month: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </td>
                  <td>{translateVia(t.via)}</td>
                  <td>{t.tag}</td>
                  <td>{t.amount > 0 ? formatFutureDuration(new Date(t.expiresAt)) : ''}</td>
                  <td>{t.remainingAmount}</td>
                  <td>{t.amount > 0 ? t.amount : t.amount * -1}</td>
                  <td>{t.adminName ? t.adminName.split(' ')[0] : adminName}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="7">
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={handlePreviousPage} disabled={userCreditPage === 1}>
                      Anterior
                    </button>
                    <button className="btn btn-primary" onClick={handleNextPage} disabled={userCredit.length < 10}>
                      Próximo
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <span>Usuário ainda não tem histórico de transações</span>
        )}

        <div>
          {isLoadingTransactions ? (
            <p>
              <Loading size={60} />
            </p>
          ) : (
            <UserConsumption
              userTransactions={userTransactions}
              handlePreviousTransactionPage={handlePreviousTransactionPage}
              handleNextTransactionPage={handleNextTransactionPage}
              transactionsPage={transactionsPage}
            />
          )}
        </div>
      </div>
    </div>
  )
}
